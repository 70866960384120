<template>
    <modal name="NewWordDinamic" v-bind:click-to-close="false" :width="400" :adaptive="true">
        <div class="row p-4" v-if="load == false">
            <div class="col-12">
                <h4>{{ title }}</h4>
                <hr>
                <div class="form-group mb-2" v-if="dimamic_id == 1">
                    <label for="">Mensaje:</label>
                    <textarea v-model="form.message" class="form-control"></textarea>
                    <div v-if="(errors && errors.message)" class="text-danger ml-3">{{ errors.message[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Categoría:</label>
                    <select class="form-control" v-model="form.word_categories_id">
                        <option>Seleciona una opción</option>
                        <option v-for="(category, index) in catalogue.word_categories" :key="index" :value="category.id">{{
                            category.name }}</option>
                    </select>
                    <div v-if="(errors && errors.word_categories_id)" class="text-danger ml-3">{{
                        errors.word_categories_id[0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Palabra:</label>
                    <input v-model="form.data.word" class="form-control">
                    <div v-if="(errors && errors['data.word'])" class="text-danger ml-3">{{ errors['data.word'][0] }}</div>
                </div>
                <div class="form-group mb-2">
                    <label for="">Tiempo:</label>
                    <datetime format="H:i" width="300px" v-model="form.data.time" ></datetime>
                    <div v-if="(errors && errors['data.time'])" class="text-danger ml-3">{{ errors['data.time'] }}</div>
                </div>

            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6">
                        <button class="btn btn-outline-warning" @click="reset">
                            <i class="fas fa-undo-alt"></i>
                            Cerrar
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-outline-success" @click="save">
                            <i class="fas fa-save"></i>
                            Guardar
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 text-center p-4">
                <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                <h5 class="mt-3">Enviando...</h5>
            </div>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';
import datetime from 'vuejs-datetimepicker';

export default {
    name: "new-word-dinamics",
    components: {
        datetime
    },
    data() {
        return {
            title: 'Crear palabra',
            dimamic_id: '',
            form: {
                id: '',
                message: '',
                word_categories_id: '',
                data: {
                    word: '',
                    time: ''
                }
            },
            errors: {
            },
            catalogue: new Catalogue(),
            load: false,
        }
    },
    created() {
        Event.$on("event-new-word-events", id => {
            this.title = 'Nuevo palabra';
            if (id) {
                this.title = 'Editar palabra';
                this.get_id(id);
            }
        });
    },
    mounted() {
        this.catalogue.get_word_categories();
        this.dimamic_id = this.$route.params.id;
    },
    methods: {
        async save() {
            this.load = true;
            let _endpoint = '';
            if (this.form.id) {
                _endpoint = `${this.dimamic_id}/dinamics/${this.form.id}`;
                this.form._method = 'PUT';
            } else {
                _endpoint = `${this.dimamic_id}/dinamics`;
            }
            try {
                let { data } = await request.post(_endpoint, this.form)
                if (data) {
                    this.reset();
                    this.successNotification(data.message);
                    Event.$emit('event-load-word-events', '');
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        async get_id(id) {
            this.form.id = id;
            let _endpoint = `${this.dimamic_id}/dinamics/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    let form = this.form;
                    Object.keys(form).forEach(key => {
                        this.form[key] = data[key];
                    });
                    if (data.time) {
                        this.form.time = data.time.substring(0, 5);
                    }
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        reset() {
            this.load = false;
            this.form.id = '';
            this.form.message = '';
            this.form.word_categories_id = '';
            this.form.data.word = '';
            this.form.data.time = '';
            this.errors = '';
            this.form._method = 'POST';
            this.$modal.hide('NewWordDinamic');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<style>
.pad {
    padding: 10px !important;
}

.mx-datepicker {
    width: 100%;
}
</style>
