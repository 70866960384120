<template>
	<modal name="Newusers" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row p-4" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group mb-2">
					<label for="">Nombre :</label>
					<input v-model="form.name" class="form-control">
					<div v-if="(errors && errors.name)" class="text-danger ml-3">{{ errors.name[0] }}</div>
				</div>
                <div class="form-group mb-2">
					<label for="">Correo :</label>
					<input v-model="form.email" class="form-control">
					<div v-if="(errors && errors.email)" class="text-danger ml-3">{{ errors.email[0] }}</div>
				</div>
                <div class="form-group mb-2">
					<label for="">Perfil :</label>
                    <select class="form-control" v-model="form.profiles_id">
                        <option value="">Selecciona una opción</option>
                        <option v-for="profile in catalogue.profiles" :key="profile.id" :value="profile.id">{{ profile.name }}</option>
                    </select>
                    <div v-if="(errors && errors.profiles_id)" class="text-danger ml-3">{{ errors.profiles_id[0] }}</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12 text-center p-4">
				<i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
				<h5 class="mt-3">Enviando...</h5>
			</div>
		</div>
	</modal>
</template>
<script>
import { mapActions } from 'vuex';
import request from '../../services/request'
import Catalogue from '../../services/catalogue';

export default {
	name: "new-users",
	data() {
		return {
			title: 'Crear usuarios',
			form: {
				id: '',
				name: '',
				email: '',
				profiles_id: '',
			},
			errors: {},
            catalogue: new Catalogue(),
			load: false,
		}
	},
	created(){
		Event.$on("event-new-users", id => {
			this.title = 'Nuevo usuario';
			if(id){
				this.title = 'Editar usuario';
				this.get_id(id);	
			}
        });
	},
	mounted() {
        this.catalogue.get_profiles();
		let id = this.$route.params.id;
		if (id) {
			this.form.proyect_id = id;
		}
	},
	methods: {
		async save() {
			this.load = true;
			let _endpoint = '';
			if(this.form.id){
				_endpoint = `/users/${this.form.id}`;
				this.form._method = 'PUT';
			}else{
				_endpoint = `/users`;
			}
			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.reset();
					this.successNotification(data.message);
					Event.$emit('event-load-users', '');
				}
			} catch (error) {
				this.load = false;
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error);
				}
			}
		},
		async get_id(id) {
			this.form.id = id;
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					let form = this.form;
					Object.keys(form).forEach(key => {
						this.form[key] = data[key];
					});
					this.form.contact_id = data.contact
					this.form.user_id = data.user
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		reset() {
			this.load = false;
			this.errors = {};
			this.form.name = '';
			this.form.email = '';
			this.form.profiles_id = '';
			this.$modal.hide('Newusers');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style>
.pad {
	padding: 10px !important;
}
.mx-datepicker {
	width: 100%;
}
</style>
