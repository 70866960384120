<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="login-box">

			<div class="col-12 text-center mb-4">
				<img src="@/assets/img/user.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
				<h3 class="login-box-msg mt-n2">Inicio de sesión</h3>
				<!-- <p class="mt-n3">Administrador</p> -->
			</div>
			<form @submit.prevent="triggerLogin" class="mt-3">
				<div class="col-12" v-if="valid_mail == false">
					<div class="input-group justify-content-center">
						<input type="text" style="width: 100px;" v-model="form.email" name="email" class="form-control"
							placeholder="Correo" :class="{ 'is-invalid': submitted && !form.email }" />
					</div>
					<div v-if="errors && errors.email" class="text-danger mt-1">{{ errors.email[0] }}</div>
				</div>
				<div class="col-12 mt-3">
					<div class="input-group" style="margin-bottom:6px !important;">
						<input type="password" v-model="form.password" name="password" class="form-control"
							placeholder="Contraseña" :class="{ 'is-invalid': submitted && !form.password }" />
					</div>
					<div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>
					<div class="row">
						<div class="col-12 text-center mt-3">
							<button class="btn btn-danger" style="width: 150px;" :disabled="submitted">
								Entrar
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-12 text-center">
				</div>
			</form>
		</div>
		<div class="logo-form">
			<img src="@/assets/img/logos_footer.png">
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			valid_mail: false,
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerLogin() {
			try {
				let login = await this.loginAction(this.form)
				if (login) {
					console.log(login);
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			loginAction: 'auth/login',
			errorNotification: 'alert/error',
		})
	}
};
</script>
