<script>
import request from '../../services/request'

export default {
    data() {
        return {
            wildcards: [],
            event_id: '',
            wildcard: null,
            type_wildcard: ['Reto', 'Pregunta'],
            showWildcard: false,
            position: 0,
            counter: 0
        }
    },
    created() {
        let _id = this.$route.params.id;

        window.Echo.channel(`changeWildcards.${ _id }`)
            .listen('ChangeWildcards', (e) => {
                
                if (e) {
                    let event = e.wildcard;
                    console.log(event);
                    if (event.showWildcard !== this.showWildcard) {
                        this.showWildcard = event.showWildcard;
                    }
                    if (event.counter !== this.counter) {
                        this.counter = event.counter;
                    }
                   
                }
            })
    },
    mounted() {
        let _id = this.$route.params.id;
        this.get_wildcard(_id);
    },
    methods: {
        async get_wildcard(id) {
            let _endpoint = `${id}/get_wildcard`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.wildcards = data.wildcard;
                    this.counter = data.wildcard.length;
                    this.event_id = data.wildcard[0].pivot.events_id;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        showQuestion() {
            this.showWildcard = !this.showWildcard;
            let tabs = {
                dinamic:{
                    dynamic_id:1
                }
            }
            Event.$emit('event-select-user', tabs);
        },
        nextWidcard() {
            this.showWildcard = false;
            this.counter--;
            this.sendStatus();
        },
        async sendStatus() {            
            let _endpoint = '';
            let event = this.wildcards[0];

            event.counter = this.counter;
            event.showWildcard = this.showWildcard;
            _endpoint = `${this.event_id}/change_wildcards`;

            try {
                await request.post(_endpoint, event);

            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        }
    },
    watch: {
        showWildcard() {
            this.sendStatus();
        },
    }
}
</script>
<template>
    <div>
        <div v-if="!showWildcard && wildcards" @click="showQuestion"
            class="circle animate__animated animate__rubberBand animate__infinite box-center">
            <span class="animate__animated animate__bounce" aria-hidden="true">{{ counter }}</span>
        </div>
        <div v-if="showWildcard">
            <div v-for="(wildcard, index_wildcard) in wildcards" :key="index_wildcard">
                <div v-if="index_wildcard == position" class="box box-center box-shadow box-size"
                    :class="wildcard.question_type == type_wildcard[0] ? 'box-border-challenge' : 'box-border-question'">
                    <h1 :class="{
                        'animate__animated animate__tada animate__infinite': showWildcard
                    }">{{ wildcard.question_type }}</h1>
                    <h4 :class="{
                        'animate__animated animate__zoomIn': showWildcard
                    }">{{ wildcard.question }}</h4>
                    <div class="row justify-content-center">
                        <button class="btn col-4 btn-sm btn-danger" @click="nextWidcard()" v-if="counter > 1">
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.circle {
    background-color: #DA291C;
    display: flex;
    width: 400px;
    height: 400px;
    justify-content: space-around;
    align-items: center;
    border-radius: 50%;
    color: gray;
    font-size: 190px;
    text-shadow: 2px 2px 2px #000;
}

.pulse {
    animation: pulse 2s infinite;
}

.box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 20px;

    h1 {
        font-weight: 900;
    }

    &-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &-size {
        width: 550px;
        height: 300px;
    }

    &-border {
        padding: 20px;
        border: 4px solid #FFD100;
        width: 100%;
        height: auto;
        border-radius: 20px;

        &-challenge {
            border: 4px solid #F29200;
            background-color: white;
            border-radius: 20px;
            font-weight: bold;
        }

        &-question {
            border: 4px solid #FFD100;
            background-color: white;
            border-radius: 20px;
            font-weight: bold;
        }
    }

    &-center {
        margin: auto;
        text-align: center;
        align-self: center;
    }

    &-word {
        text-align: center;
        font-weight: bold;
        color: #FFD100;
    }

    &-status-game {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: #FFD100;
        font-size: small;
    }
}
</style>