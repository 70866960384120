import request from './request'

export default class Catalogos {

    constructor() {
        this.profiles = [];
        this.question_types = [];
        this.event_types = [];
        this.questionnaires = [];
        this.dynamic_types = [];
        this.word_categories = [];
        this.users_moderators = [];
    }

    async get_profiles() {
        let _endpoint = '/catalogue/profiles';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.profiles = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_question_types() {
        let _endpoint = '/catalogue/question_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.question_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_event_types() {
        let _endpoint = '/catalogue/event_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.event_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_questionnaires() {
        let _endpoint = '/catalogue/questionnaires';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.questionnaires = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_dynamic_types(){
        let _endpoint = '/catalogue/dynamic_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.dynamic_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_word_categories(){
        let _endpoint = '/catalogue/word_categories';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.word_categories = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_moderators(){
        let _endpoint = '/catalogue/moderators';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.users_moderators = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

}