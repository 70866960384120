import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Inicio
import Home from '../pages/HomePage'
import Login from '../pages/auth/LoginPage'

// Admin
import AdminTheme from '../pages/layouts/AdminTheme'
import Dashboard from '../pages/admin/DashboardPage'
import Users from '../pages/admin/UsersPage'
import Participants from '../pages/admin/ParticipantsPage'
import Dynamics from '../pages/admin/dynamics/DynamicsPage'
import DynamicsList from '../pages/admin/dynamics/DynamicsListPage'
import Questionnaire from '../pages/admin/QuestionnairePage'
import Events from '../pages/admin/events/EventsPage'
import EventsForm from '../pages/admin/events/EventsForm'
import EventShow from '../pages/admin/events/EventShowPage'
import RegisterUser from '../pages/users/RegisterUserPage'
import WildCard from '../pages/admin/WildCardPage'

// Moderator
import DashboardM from '../pages/moredator/DashboardPage';
import ModeratorTheme from '../pages/layouts/ModeratorTheme'

// Presenter
import DashboardP from '../pages/presenter/DashboardPage';
import PrsenterTheme from '../pages/layouts/PrsenterTheme';

// Minuta
import LogBook from '@/components/LogBook/LogBookForm.vue'

// ? componentes para el usuario
/* import ModeratorTheme from '../pages/layouts/ModeratorTheme'
import DashMoredator from '../pages/moredator/DashboardPage'*/
import Pictionary from '../pages/moderator/PictionaryPage'
import GuessWord from '../pages/moderator/GuessWordPage'
import Roulette from '../pages/moderator/RoulettePage'
import Minuta from '../pages/moderator/MinutaPage'
import UserControl from '../pages/moderator/UserControlPage'
import Gratitude from '../pages/moderator/GratitudePage'
import EventFinish from '../pages/moderator/EventFinishPage'
// presenter
import PictionaryPresenter from '../pages/presenter/PictionaryPage';
Vue.use(VueRouter)

const routes = [
	{ path: '/', name: 'home', component: Home },
	{ path: '/login', name: 'login', component: Login },
	{ path: '/register-users/:tkn', name: 'register-users', component: RegisterUser },
	{
		path: '/dashboard',
		name: 'admin',
		component: AdminTheme,
		children: [
			{
				name: 'dashboard',
				path: '/dashboard',
				component: Dashboard
			},
			{
				name: 'events',
				path: '/events',
				component: Events
			},
			{
				name: 'form_events',
				path: '/events/form/:id?',
				component: EventsForm,
			},
			{
				name: 'show_events',
				path: '/events/show/:id?',
				component: EventShow,
			},
			{
				name: 'users',
				path: '/users',
				component: Users
			},
			{
				name: 'participants',
				path: '/participants',
				component: Participants
			},
			{
				name: 'questionnaire',
				path: '/questionnaire',
				component: Questionnaire
			},
			{
				name: 'wildcards',
				path: '/wildcards',
				component: WildCard
			},
			{
				name: 'dynamics',
				path: '/dynamics',
				component: Dynamics
			},
			{
				name: 'dynamics-list',
				path: '/dynamics-list/:id',
				component: DynamicsList
			}
		]
	},
	{
		path: '/moderator',
		name: 'moderator',
		component: ModeratorTheme,
		children: [
			{
				name: 'moderator',
				path: '/moderator/:id',
				component: DashboardM
			},
			{
				name: 'mode-pictionary',
				path: '/moderator/:id/pictionary',
				component: Pictionary
			},
			{
				name: 'mode-guess-word',
				path: '/moderator/:id/guess-word',
				component: GuessWord
			},
			{
				name: 'mode-roulette',
				path: '/moderator/:id/roulette',
				component: Roulette
			},
			{
				name: 'mode-minuta',
				path: '/moderator/:id/minuta',
				component: Minuta
			},
			{
				name: 'mode-user-control',
				path: '/moderator/:id/user-control',
				component: UserControl
			},
			{
				name: 'mode-gratitude',
				path: '/moderator/:id/gratitude',
				component: Gratitude
			},
			{
				name: 'mode-event-finish',
				path: '/moderator/:id/event-finish',
				component: EventFinish
			},
		]
	},
	{
		path: '/presenter',
		name: 'presenter',
		component: PrsenterTheme,
		children: [
			{
				name: 'presenter',
				path: '/presenter/:id',
				component: DashboardP
			},
			{
				name: 'presenter-pictionary',
				path: '/presenter/:id/pictionary',
				component: PictionaryPresenter
			},
		]
	},
	{
		path: '/logbook/:event_id',
		name: 'LogBook',
		component: LogBook
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {

	// Cargador de pagina
	if (to.name != from.name) {
		store.state.status.routeChange = true;
	}

	// Accesos publicos
	const publicPages = ['login', 'register-users', 'home'];
	const authRequired = !publicPages.includes(to.name);

	// Verificamos si esta logueado
	const loggedIn = JSON.parse(localStorage.getItem('user'));


	// Redirige a home si se loguea
	if (loggedIn && !authRequired) {
		return next('/dashboard');
	}

	// Redirige a login si no esta logueado
	if (authRequired && !loggedIn) {
		return next('/login');
	}

	next();
});

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}

})


export default router
