<script>
import request from '@/services/request';
import { mapActions } from 'vuex';
export default {
    name: 'new-wildcards',
    data() {
        return {
            title: 'Crear comodín',
            form: {
                id: '',
                question_type: '',
                question: ''
            },
            optionsWildcard: [
                {
                    id: 1,
                    title: 'Reto'
                },
                {
                    id: 2,
                    title: 'Pregunta'
                }
            ],
            errors: {},
            load: false
        }
    },
    created(){
        Event.$on('event-new-wildcards', id => {
            this.title = 'Nuevo comodín';
            if(id){
                this.title = "Editar comodín";
                this.get_id(id);
            }
        })
    },
    mounted() {

    },
    methods: {
        async save(){
            this.load = true;
            let _endpoint = '';
            if(this.form.id){
                _endpoint = `/wildcards/${this.form.id}`;
                this.form._method = 'PUT';
            }else {
                _endpoint = `/wildcards`;
                delete(this.form._method);
            }
            try{
                const { data } = await request.post(_endpoint, this.form);
                if(data) {
                    this.reset();
                    this.successNotification(data.message);
                    Event.$emit('event-load-wildcards', '');
                }
            }catch(error){
                this.load = false;
                this.errors = {};
                if(error.response){
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        async get_id(id) {
            this.form.id = id;
            const _endpoint = `/wildcards/${id}`;
            try{
                let { data } = await request.get(_endpoint)
                if(data) {
                    this.form.question_type = data.question_type
                    this.form.question = data.question;
                    this.status = data.statuses_id;
                }
            }catch(error) {
                this.errors = {};
                if(error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        reset(){
            this.form.id = '';
            this.load = false;
            this.errors = {}
            this.form.question = '';
            this.form.question_type = '';
            this.$modal.hide('Newwildcard');
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<template>
	<modal name="Newwildcard" v-bind:click-to-close="false" :width="400" :adaptive="true">
		<div class="row p-4" v-if="load == false">
			<div class="col-12">
				<h4>{{ title }}</h4>
				<hr>
				<div class="form-group mb-2">
					<label for="">Tipo de comodín:</label>
					<select v-model="form.question_type" class="form-control">
                        <option v-for="option in optionsWildcard" :key="option.id" :value="option.title">{{ option.title }}</option>
                    </select>
					<div v-if="(errors && errors.question_type)" class="text-danger ml-3">{{ errors.question_type[0] }}</div>
				</div>
				<div class="form-group mb-2">
					<label for="">Comodín:</label>
					<input v-model="form.question" class="form-control">
					<div v-if="(errors && errors.question)" class="text-danger ml-3">{{ errors.question[0] }}</div>
				</div>
			</div>
			<div class="col-12 mt-3">
				<div class="row">
					<div class="col-6">
						<button class="btn btn-outline-warning" @click="reset">
							<i class="fas fa-undo-alt"></i>
							Cerrar
						</button>
					</div>
					<div class="col-6 text-right">
						<button class="btn btn-outline-success" @click="save">
							<i class="fas fa-save"></i>
							Guardar
						</button>

					</div>
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-md-12 text-center p-4">
				<i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
				<h5 class="mt-3">Enviando...</h5>
			</div>
		</div>
	</modal>
</template>