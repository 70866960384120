<template>
	<div class="container">
		<div class="row bg align-items-center justify-content-center">
			<div class="col-12" style="">
				<div class="row">
					<div class="col-md-7 col-12 text-left d-flex align-items-center justify-center">
						<div class="col-6">
							<h1>Conectando <br>entre <span class="text-danger font-weight-bold">líderes</span></h1>
							<p class="lead mb-5 text-md">
								¡Qué ganas de pasar un rato agradable con compañeros y amigos, ¿no crees? ¡Vivamos uno de
								ellos
								reunidos en nuestro ritual Conectando entre líderes!
							</p>
							<router-link to="/login" class="btn btn-danger btn-lg col-5">
								Entrar
							</router-link>
						</div>
					</div>
					<div class="col-md-5 col-10 p-4">
						<div class="row align-items-center justify-content-center">
							<img src="@/assets/img/logo_lideres.png" class="img-fluid" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="logo-form">
			<img src="@/assets/img/logos_footer.png">
		</div>
	</div>
</template>
<script>
export default {

}
</script>
<style>
.bg{
	background-color: transparent;
}
</style>