<template>
    <div class="col-12 mt-3">
        <WordFormComponent />
        <router-link :to="{ name: 'dynamics' }" class="btn btn-outline-secondary btn-sm pl-4 pr-4">
            <i class="fa-solid fa-arrow-left"></i>
            Regresa
        </router-link>
        <div class="card mt-3">
            <div class="card-header">
                <h3 class="card-title">Lista de palabras - ({{ getTypeText()}})</h3>
                <button class="btn btn-sm btn-success float-right" @click="showModalWord('')">
                    <i class="fa fa-plus"></i> Agregar
                </button>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th v-if="id == 1">Mensaje</th>
                                <th>Categoría</th>
                                <th>Palabra</th>
                                <th>Estatus</th>
                                <th>Tiempo</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="participants">
                            <tr v-for="(participant, index) in participants.data" :key="index">
                                <td>{{ participant.id }}</td>
                                <td v-if="id == 1">{{ participant.message }}</td>
                                <td>{{ participant.category.name }}</td>
                                <td>{{ participant.data.word }}</td>
                                <td>
                                    <h6>
                                        <span class="badge" :class="participant.statuses_id == 1 ? ' badge-success': ' badge-danger'">{{ participant.status.name }}</span>
                                    </h6>
                                </td>
                                <td>{{ participant.data.time }}</td>
                                <td>
                                    <button @click=showModalWord(participant.id) class="btn btn-primary btn-sm">
                                        <i class="fas fa-edit "> </i>
                                    </button>
                                    <button type="button" @click="deleted(participant.id)"
                                        class="m-2 btn-danger btn btn-sm ">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" colspan="5">Sin resultados.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import WordFormComponent from '@/components/Dinamics/WordFormComponent.vue';
import request from '../../../services/request';
import Catalogue from '../../../services/catalogue';

export default {
    name: "list-word-events",
    components: {
        WordFormComponent
    },
    data() {
        return {
            id: '',
            search: {
                name: ''
            },
            catalogue: new Catalogue(),
            participants: []
        }
    },
    methods: {
        getTypeText() {
            let text = '';
            let id = this.id;
            if (id) {
                let types = this.catalogue.dynamic_types;
                text = types.filter(function (value) { return value.id == id });
                if (text.length > 0) {
                    return text[0].name;
                }
            }
        },
        showModalWord(id) {
            Event.$emit('event-new-word-events', id);
            this.$modal.show('NewWordDinamic');
        },
        deleted: function (id) {
            let tit = 'Eliminar dinámica';
            let txt = 'Seguro que quiere eliminar la dinámica';

            let vm = this;
            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    vm.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
			let _endpoint =  `${this.id}/dinamics/${id}`;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
        async list(page = 1) {
            let service = request;
            let _endpoint =`${this.id}/dinamics`;
            try {
                let { data } = await service.list(_endpoint, page, {})
                if (data) {
                    this.participants = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.catalogue.get_dynamic_types();
            this.list();
        }
    },
    created() {
        Event.$on("event-load-word-events", () => {
            this.list();
        });
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}
</style>
