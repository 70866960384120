<template>
    <div class="col-12">
        <ParticipantsForm />
        <ParticipantsImport :modal_id="modals.participant_import" />
        <div class="row">
            <div class="col-12">
                <h4>{{ title }}</h4>
            </div>
            <div class="col-md-5 mt-3">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title"> Información del evento</h3>
                    </div>
                    <div class="card-body">
                        <div class="row p-2" v-if="load == false">
                            <div class="col-12">
                                <div class="form-group mb-2">
                                    <label for="">Título:</label>
                                    <input v-model="form.name" class="form-control">
                                    <div v-if="(errors && errors.name)" class="text-danger ml-3">{{ errors.name[0] }}
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">Fecha:</label>
                                    <input type="date" v-model="form.date" :min="minDate()" class="form-control">
                                    <div v-if="(errors && errors.date)" class="text-danger ml-3">{{ errors.date[0] }}
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">Hora:</label>
                                    <input type="time" v-model="form.time" class="form-control">
                                    <div v-if="(errors && errors.time)" class="text-danger ml-3">{{ errors.time[0] }}
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">Moderador:</label>
                                    <select class="form-control" v-model="form.moderator_id">
                                        <option value="">Selecciona una opción</option>
                                        <option v-for="usr in catalogue.users_moderators" :key="usr.id"
                                            :value="usr.id">{{ usr.name }}</option>
                                    </select>
                                    <div v-if="(errors && errors.moderator_id)" class="text-danger ml-3">{{
                                        errors.moderator_id[0] }}</div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">Tipo de evento:</label>
                                    <select class="form-control" v-model="form.event_types_id">
                                        <option value="">Selecciona una opción</option>
                                        <option v-for="type_event in catalogue.event_types" :key="type_event.id"
                                            :value="type_event.id">{{ type_event.name }}</option>
                                    </select>
                                    <div v-if="(errors && errors.event_types_id)" class="text-danger ml-3">{{
                                        errors.event_types_id[0] }}</div>
                                </div>
                                <div v-if="form.event_types_id === 3" class="form-group mb-2">
                                    <label for="">Temas de CHarlando:</label>
                                    <multiselect v-model="taggingSelected" :options="taggingOptions" :multiple="true"
                                        :taggable="true" @tag="addTag" tag-placeholder="Agregar un nuevo tema"
                                        placeholder="Escribe para buscar o agregar un tema" selected-label="Seleccionado"
                                        deselect-label="Precione Enter para quitar tema" label="name" track-by="code">
                                    </multiselect>
                                    <div v-if="(errors && errors.data)" class="text-danger ml-3">{{
                                        errors.data[0] }}</div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">Cuestionario:</label>
                                    <select class="form-control" v-model="form.questionnaires_id">
                                        <option value="">Selecciona una opción</option>
                                        <option v-for="questionnaire in catalogue.questionnaires" :key="questionnaire.id"
                                            :value="questionnaire.id">{{ questionnaire.name }}
                                        </option>
                                    </select>
                                    <div v-if="(errors && errors.questionnaires_id)" class="text-danger ml-3">{{
                                        errors.questionnaires_id[0] }}</div>
                                </div>
                                <div class="form-group mb-2">
                                    <label for="">URL:</label>
                                    <input type="url" class="form-control" v-model="form.url">
                                    <div v-if="(errors && errors.url)" class="text-danger ml-3">{{ errors.url[0] }}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row" v-else>
                            <div class="col-md-12 text-center p-4">
                                <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                                <h5 class="mt-3">Enviando...</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="row" v-if="load == false">
                    <div class="col-12 mt-3">
                        <div class="card">
                            <div class="card-header box">
                                <h3 class="card-title float-left"> Participantes </h3>
                                <div class="box-input-search float-right">
                                    <input type="search" v-model="search" class="form-control"
                                        placeholder="Buscar participantes" />
                                    <button class="btn btn-sm btn-success float-right btn-h"
                                        @click="showModal(modals.participant_import)">
                                        <i class="fa fa-upload"></i> <span class="ml-1">Importar</span>
                                    </button>
                                    <button class="btn btn-sm btn-success float-right btn-h" @click="showModalUser('')">
                                        <i class="fa fa-plus"></i> <span class="ml-1">Nuevo</span>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body" style="min-height: 400px; max-height: 600px;">
                                <div class="table-responsive overflow-auto" style="max-height: 575px;">
                                    <table class="table table-bordered text-center text-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>Correo</th>
                                                <th>Núm. Empleado</th>
                                                <th>En eventos</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="participantsSelected && !searchActive">
                                            <tr v-for="(participant, index) in participantsSelected" :key="index">
                                                <td>
                                                    <button class="btn-danger btn btn-sm"
                                                        @click="selectParticipants(participant)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </td>
                                                <td>{{ participant.name }} {{ participant.last_name }}</td>
                                                <td>{{ participant.email }}</td>
                                                <td>{{ participant.code }}</td>
                                                <td>
                                                    <h4>
                                                        <span class="badge bg-secondary">{{ participant.events.length
                                                        }}</span>
                                                    </h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="participantsSelected.length == 0 && !searchActive">
                                            <tr>
                                                <td align="center" colspan="5">Sin resultados.</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="participantsSearch && searchActive">
                                            <tr v-for="(participant, index) in participantsSearch" :key="index">
                                                <td>
                                                    <input type="checkbox" @click="selectParticipants(participant)"
                                                        :value="participant.id" v-model="form.participants">
                                                </td>
                                                <!-- <td>{{ participant.id }}</td> -->
                                                <td>{{ participant.name }} {{ participant.last_name }}</td>
                                                <td>{{ participant.email }}</td>
                                                <td>{{ participant.code }}</td>
                                                <td>
                                                    <h4>
                                                        <span class="badge bg-secondary">{{ participant.events.length
                                                        }}</span>
                                                    </h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="(errors && errors.participants)" class="text-danger ml-3">{{
                                        errors.participants[0] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    
                    <div class="col-12 mt-3">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title"> Participantes </h3>
                            </div>
                            <div class="card-body">
                                <div class="col-md-12 text-center p-4">
                                    <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                                    <h5 class="mt-3">Enviando...</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="row">
                    <div class="col-6 text-left">
                        <router-link :to="{ name: 'events' }" class="btn btn-warning">
                            <i class="fas fa-undo-alt"></i> Cancelar
                        </router-link>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn btn-success" v-if="load == false" @click="save">
                            <i class="fas fa-save"></i>
                            Guardar
                        </button>
                        <button v-else class="btn btn-success" disabled>
                            <div class="fa fa-spinner fa-spin"></div>
                            Guardando
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import * as _ from 'lodash';
import request from '../../../services/request'
import Catalogue from '../../../services/catalogue';
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import ParticipantsForm from '@/components/Participants/ParticipantsFormComponent.vue';
import ParticipantsImport from '@/components/Participants/ParticipantsImportComponent.vue';

export default {
    name: "new-events",
    components: {
        Multiselect,
        ParticipantsForm,
        ParticipantsImport
    },
    data() {
        return {
            title: 'Crear eventos',
            form: {
                id: '',
                name: '',
                date: '',
                time: '',
                event_types_id: '',
                questionnaires_id: '',
                url: '',
                data: null,
                moderator_id: '',
                participants: []
            },
            modals: {
                participant_import: 'ParticipantsImport',
            },
            searchActive: false,
            search: '',
            participantsSelected: [],
            participantsSearch: [],
            taggingOptions: [],
            taggingSelected: [],
            participants: [],
            errors: {},
            catalogue: new Catalogue(),
            load: false,
        }
    },
    watch: {
        "search": function () {
            this.debounceSearch();
        }
    },
    created() {
        Event.$on('event-load-participants', () => {
            this.list();
        });
        Event.$on('event-import-participants', (users) => {
            let participants = this.form.participants;
            this.form.participants = participants.concat(users);
            let vm = this;
            this.participantsSelected = [];
            this.form.participants.forEach(function (id) {
                vm.insetUser(id);
            });
        });
    },
    mounted() {
        this.list();
        this.catalogue.get_event_types();
        this.catalogue.get_questionnaires();
        this.catalogue.get_moderators();
        let id = this.$route.params.id;
        if (id) {
            this.get_id(id);
        }
    },
    methods: {
        async insetUser(id) {
            let _endpoint = `/participants/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.selectParticipants(data);
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        debounceSearch: _.debounce(function () {
            if (this.search != '') {
                this.searchActive = true;
                this.searchParticipants();
            } else {
                this.searchActive = false;
            }
        }, 400),
        showModalUser(id) {
            Event.$emit('event-new-participants', id);
            this.$modal.show('NewParticipants');
        },
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.taggingOptions.push(tag)
            this.taggingSelected.push(tag)
        },
        minDate() {
            let dte = moment().add(1, 'days').format("YYYY-MM-DD");
            return dte;
        },
        async save() {
            this.load = true;
            if (this.form.event_types_id === 3) {
                const data = {
                    topics: this.taggingSelected
                };
                this.form.data = JSON.stringify(data);
            } else {
                this.form.data = null;
            }
            /* let ids = this.participantsSelected.map(x => x['id']);
            this.form.participants = ids; */
            let _endpoint = '';
            if (this.form.id) {
                _endpoint = `/events/${this.form.id}`;
                this.form._method = 'PUT';
            } else {
                _endpoint = `/events`;
            }
            try {
                let { data } = await request.post(_endpoint, this.form)
                if (data) {
                    this.successNotification(data.message);
                    this.$router.push({ name: 'events' })

                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        async get_id(id) {
            this.form.id = id;
            let vm = this;
            let _endpoint = `/events/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    let form = this.form;
                    Object.keys(form).forEach(key => {
                        this.form[key] = data[key];
                    });
                    if (this.form.data) {
                        this.form.data = JSON.parse(this.form.data);
                        this.taggingSelected = this.form.data.topics
                        this.taggingOptions = this.form.data.topics
                    }
                    if (data.time) {
                        this.form.time = data.time.substring(0, 5);
                    }
                    if (data.participants) {
                        this.participantsSelected = data.participants;
                        let ids = data.participants.map(x => x['id']);
                        vm.form.participants = ids;
                    }
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error)
                }
            }
        },
        async list(page = 1) {
            let service = request;
            let _endpoint = '/participants';
            try {
                let { data } = await service.list(_endpoint, page, {})
                if (data) {
                    this.participants = data;
                }
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showModal(_modal) {
            let vm = this;
            setTimeout(() => {
                vm.$modal.show(_modal);
            }, 100);
        },
        async searchParticipants() {
            try {
                let services = request;
                let _endpoint = `/participants/search/${this.search}`;
                let { data } = await services.get(_endpoint);
                if (data) {
                    this.participantsSearch = data
                }
            } catch (error) {
                this.$snotify.error('Ups. Parece que algo salio mal...');
            }
        },
        selectParticipants(participant = null) {
            if (participant) {
                const index = this.participantsSelected.findIndex(item => item.id === participant.id);
                if (index !== -1) {
                    this.participantsSelected.splice(index, 1);
                    this.form.participants.splice(index, 1);
                } else {
                    this.participantsSelected.push(participant);
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.btn-h {
    display: flex;
    padding: 10px 15px;
}

.btn-h i {
    font-size: 17px;
    ;
}

.btn-h span {
    display: none;
}

.btn-h:hover span {
    display: block;
}

.pad {
    padding: 10px !important;
}

.mx-datepicker {
    width: 100%;
}

.multiselect {
    border-radius: 50px !important;
}

.box {
    align-items: center;

    h3 {
        justify-content: flex-start;
    }

    div {
        width: 70%;
        display: flex;
        justify-content: space-between;
        gap: 5px;

        input {
            justify-content: flex-start;
        }

        button {
            justify-content: flex-end;
        }
    }

}
</style>
