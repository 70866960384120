<script>
// import TimerComponent from '../TimerComponent.vue'
import TimerComponent from '../TimernNewComponent.vue'

export default {
    components: {
        TimerComponent
    },
    props: {
        event: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            pictionary: {
                time: '02:00',
                attempts: 0,
                available_attempts: 5,
                success_attempts: 0,
                max_attempts_word: 8,
                success: true,
                word: 'Principio'
            },
            message: {
                error: '¡No lograste completar la dinámica! No te desanimes puedes seguir practicando',
                success: 'Excelente, has demostrado ser un colaborador extraordinario',
            },
            flag_word: false,
            status_game:false,
            showResult: false,
        }
    },
    created() {
        let dinamic = this.event;
        this.pictionary.word = dinamic.data.word;
        this.pictionary.time = dinamic.data.time;

        let vm = this;
        window.Echo.channel(`statusGame.${dinamic.pivot.id}`)
            .listen('StatusGame', (e) => {
                console.log(e);
                if (e) {
                    let event = e.game;
                    if (event.status_game !== vm.status_game) {
                        vm.status_game = event.status_game;
                    }

                    if(event.max_attempts_word !==  vm.pictionary.max_attempts_word){
                        vm.pictionary.max_attempts_word = event.max_attempts_word;
                    }

                    if(event.success_attempts == true){
                        vm.flag_word = true;
                        vm.pictionary.success = true;
                        vm.showModal();
                    }

                    if(event.success_attempts == false && event.finish == true){
                        vm.flag_word = true;
                        vm.pictionary.success = false;
                        vm.showModal();
                    }
                }
            })
        // Esto es cuando el tiempo termina automaticamente mostrare el mensaje
        Event.$on('finish', () => {
            this.pictionary.success = false;
            this.showModal();
        });

    },
    mounted() {
        /*
            cuando funcionen los sockets solo vas a mandar los datos de pictionary es la orden del tiempo que se pare
            para los intentos estarian asi son 8 y se estarian reiniciando en cada mensaje

        */
    },
    methods: {
        // metodo que inicia el modal datos a necesitar es si fue resuelto o no que vendria en el
        /**
         * ! this.pictionary.word: ''
         * aqui vendra la palabra esos dos para mostrar
         * ! this.pictionary.success:
         * si es true mostrara el mensaje de bueno y si es false el mensaje correspondiente
         */
        showModal() {
            /* this.$modal.show('messagePresenter'); */
            this.showResult = true;
            setTimeout(() => {
                this.flag_word = true;
                // setTimeout(() => {
                //     this.$modal.hide('messagePresenter');
                // }, 2000);
            }, 5000);
        }        
    }
}
</script>

<template>
    <div class="bg-word">
        <div class="center">
            <h3>Es tiempo de dibujar</h3>
            <h4>¡Somos entusiastas y siempre positivos!</h4>
            <div class="box box-center box-shadow box-size">
                <div class="box-border mb-2 box-shadow">
                    <h3 class="text-warning" v-if="showResult">
                        <span v-if="pictionary.success">¡Felicidades adivinaste la palabra!</span>
                        <span v-if="!pictionary.success && pictionary.max_attempts_word != 0">El tiempo ha finalizado y no lograron adivinar la palabra, pero la siguiente vez ¡lo conseguirán!, porque nada nos detiene.</span>
                        <span v-if="!pictionary.success && pictionary.max_attempts_word == 0">¡No adivinaste la palabra!</span>
                    </h3>
                    <h1 class="box-word" v-else>
                        <timer-component :prop_time="pictionary.time" :prop_start="status_game" @finish="gameResult(2)"></timer-component>
                    </h1>
                </div>
                <div class="box-status-game">
                    <!-- <h6>Rondas: {{ pictionary.attempts }} / {{ pictionary.available_attempts }}</h6>
                    <h6>Rondas acertada: {{ pictionary.success_attempts }}</h6> -->
                    <h6>Solo tienes: {{ pictionary.max_attempts_word }} {{ pictionary.max_attempts_word == 1 ? 'intento' :
                        'intentos' }}</h6>
                </div>
            </div>
            <div v-if="showResult" class="box box-center box-size bg-white" :class="{
                'box-border-success': pictionary.success,
                'box-border-error': !pictionary.success,
            }">
                <!-- <h1 v-if="flag_word">{{ pictionary.success ? message.success : message.error }}</h1>-->
                <h1 v-if="flag_word">{{ pictionary.word }}</h1>
            </div>
        </div>
        <!-- Modal para mensaje -->
        <!-- <modal name="messagePresenter" v-bind:click-to-close="true" :width="700" :adaptive="true">
            <div class="box box-center" :class="{
                'box-border-success': pictionary.success,
                'box-border-error': !pictionary.success,
            }">
                <h1 v-if="!flag_word">{{ pictionary.success ? message.success : message.error }}</h1>
                <h1 v-if="flag_word">{{ pictionary.word }}</h1>
            </div>
        </modal> -->
    </div>
</template>

<style lang="scss" scoped>
.bg-word {
    margin: 0 !important;
    background-image: url('@/assets/img/gifLapiz_snFondo.gif');
    /* Center and scale the image nicely */
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    max-height: 500px;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;

    &-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &-size {
        width: 90%;
    }

    &-border {
        padding: 20px;
        border: 4px solid #FFD100;
        width: 100%;
        height: auto;
        border-radius: 20px;

        &-success {
            border: 4px solid #005807;
            border-radius: 20px;
            font-weight: bold;
            color: #005807;
            text-shadow: 2px 2px 2px rgba($color: #FFD100, $alpha: 0.5);
        }

        &-error {
            border: 4px solid #880a01;
            border-radius: 20px;
            font-weight: bold;
            color: #880a01;
            text-shadow: 2px 2px 2px rgba($color: #FFD100, $alpha: 0.5);
        }
    }

    &-center {
        margin: auto;
        text-align: center;
        align-self: center;
    }

    &-word {
        text-align: center;
        font-weight: bold;
        color: #FFD100;
    }

    &-status-game {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: #FFD100;
        font-size: small;
    }
}

.btn {

    &-primary {
        color: white !important;
        background: #F29200;
        border: 2px solid #F29200 !important;
    }

    &-outline-primary {
        color: #F29200;
        background: white;
        border: 2px solid #F29200 !important;
    }
}

.box-size {
    box-shadow: none;
}

.vm--overlay{
    left: 20% !important;
    height: 100%;
}
</style>