<script>
// import TimerComponent from './TimerComponent.vue';
import TimerComponent from './TimernNewComponent.vue'

import request from '../../services/request'

export default {
    components: { TimerComponent },
    props: {
        event: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            lettersSelected: [],
            question: '',
            word: '',
            time: '',
            success: false,
            errors: 0,
            trys: 8,
            start_game: false,
            init_count: false,
            finish: false,
        }
    },
    methods: {
        parseWord() {
            let word = this.word;
            return word.split('');
        },
        getLetter(letter) {
            let letters = this.lettersSelected;
            let array = this.parseWord();
            if (letters.includes(letter)) {
                return false;
            }

            if (!array.includes(letter)) {
                this.errors += 1;
                this.trys -= 1;
            }
            if (this.errors == 8) {
                this.init_count = false;
                this.sentResult();
            }


            letters.push(letter);
            this.lettersSelected = letters;
        },
        sentResult(typeM = 'error') {
            Event.$emit('finish-timer');

            let dinamic = this.event;
            dinamic.status = typeM == 'error' ? 2 : 1;
            Event.$emit('finish-game', dinamic);

            this.sendStatus();
            let url = require(`../../assets/img/guess_word_error.png`);
            let title = '¡Incorrecto!';

            if (typeM == 'success') {
                url = require(`../../assets/img/guess_word_ok.png`);
                title = '¡Correcto!';
            }

            let vm = this;
            this.finish = true;
            setTimeout(function () {
                vm.$swal({
                    imageUrl: url,
                    timer: 3000,
                    imageHeight: 300,
                    title: title,
                    background: '#e1e1e1',
                    imageAlt: 'icono',
                    confirmButtonText: `Siguiente`,
                    confirmButtonColor: '#086d4a'
                });

            }, 100);

        },
        useLetter(letter) {
            let letters = this.lettersSelected;
            return letters.includes(letter);
        },
        showWord() {
            let Rword = '';
            let array = this.parseWord();
            let letters = this.lettersSelected;
            let countSucces = 0;
            for (let index = 0; index < array.length; index++) {
                if (letters.includes(array[index])) {
                    countSucces++;
                    Rword += ' ' + array[index] + ' ';
                } else {
                    Rword += ' _ ';
                }
            }
            if (countSucces == array.length) {
                this.init_count = false;
                this.success = true;
            }
            return Rword;
        },
        async sendStatus() {
            let _endpoint = '';
            let event = this.event;
            event.lettersSelected = this.lettersSelected;
            event.success = this.success;
            event.trys = this.trys;
            event.start_game = this.start_game;

            _endpoint = `${this.event.pivot.events_id}/status_game`;

            try {
                let { data } = await request.post(_endpoint, event)
                if (data) {
                    console.log(data);
                }
            } catch (error) {
                this.load = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        starGame() {
            this.sendStatus;
            this.start_game = true;
            this.init_count = true;
            // Event.$emit('start-timer', this.time);
        },
        showResponse() {
            let word = this.word;
            let question = this.question;
            let array = this.parseWord();
            let letters = '';
            for (let index = 0; index < array.length; index++) {
                letters += ' _ ';
            }
            if (this.success) {
                this.sentResult('success');
                return question;
            } else {
                return question.replace(word, letters);
            }
        }
    },
    watch: {
        start_game() {
            this.sendStatus();
        },
        lettersSelected() {
            this.sendStatus();
        },
        success() {
            this.sendStatus();
        }
    },
    created() {
        let dinamic = this.event;
        this.question = dinamic.message;
        this.word = dinamic.data.word;
        this.time = dinamic.data.time;
        let letters = this.lettersSelected;
        let vm = this;
        window.Echo.channel(`statusGame.${dinamic.pivot.id}`)
            .listen('StatusGame', (e) => {
                if (e) {
                    let event = e.game;
                    if (event.start_game !== this.start_game) {
                        this.starGame();
                    }

                    if (event.lettersSelected !== letters) {
                        event.lettersSelected.forEach(function (letter) {
                            if (!letters.includes(letter)) {
                                vm.getLetter(letter);
                            }
                        });
                    }
                }
            })
    }
}
</script>
<template>
    <div class="row bg-word">
        <div class="col-12 p-5 text-center" style="margin:170px 0px;" v-if="start_game == false">
            <button class="btn col-4 btn-lg btn-danger btn_center" @click="starGame()"> Iniciar </button>
        </div>
        <div class="col-12" :style="start_game ? '' : 'display:none;'">
            <div class="row">
                <div class="col-5 div_trys">
                    <h3>Número de intentos <br>{{ trys }}</h3>
                </div>
                <div class="col-6 text-right" style="font-size:45px;">
                    <timer-component :prop_time="time" :prop_start="init_count" @finish="sentResult()"></timer-component>
                </div>
            </div>
        </div>
        <div class="col-12 p-5" v-if="start_game">
            <div class="row justify-content-center">
                <div class="col-8  bg_text text-light">
                    <h4 class="ml-4 mt-4 mb-5 mr-4">{{ showResponse() }}</h4>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-5 mt-4 mb-5 text-light bg_response">
                    <h5 class="ml-4 mr-4 mt-3 mb-3">{{ showWord() }}</h5>
                </div>
            </div>
            <div class="row justify-content-center" v-if="!finish">
                <div class="col-lg-9 col-md-11 col-12 p-4 bg_query">
                    <div class="row_letters row justify-content-center">
                        <div class="letter" :class="useLetter('A') ? 'letterUsed' : ''" @click="getLetter('A')">A</div>
                        <div class="letter" :class="useLetter('B') ? 'letterUsed' : ''" @click="getLetter('B')">B</div>
                        <div class="letter" :class="useLetter('C') ? 'letterUsed' : ''" @click="getLetter('C')">C</div>
                        <div class="letter" :class="useLetter('D') ? 'letterUsed' : ''" @click="getLetter('D')">D</div>
                        <div class="letter" :class="useLetter('E') ? 'letterUsed' : ''" @click="getLetter('E')">E</div>
                        <div class="letter" :class="useLetter('F') ? 'letterUsed' : ''" @click="getLetter('F')">F</div>
                        <div class="letter" :class="useLetter('G') ? 'letterUsed' : ''" @click="getLetter('G')">G</div>
                        <div class="letter" :class="useLetter('H') ? 'letterUsed' : ''" @click="getLetter('H')">H</div>
                        <div class="letter" :class="useLetter('I') ? 'letterUsed' : ''" @click="getLetter('I')">I</div>
                        <div class="letter" :class="useLetter('J') ? 'letterUsed' : ''" @click="getLetter('J')">J</div>
                        <div class="letter" :class="useLetter('K') ? 'letterUsed' : ''" @click="getLetter('K')">K</div>
                    </div>
                    <div class="row_letters row justify-content-center">
                        <div class="letter" :class="useLetter('L') ? 'letterUsed' : ''" @click="getLetter('L')">L</div>
                        <div class="letter" :class="useLetter('M') ? 'letterUsed' : ''" @click="getLetter('M')">M</div>
                        <div class="letter" :class="useLetter('N') ? 'letterUsed' : ''" @click="getLetter('N')">N</div>
                        <div class="letter" :class="useLetter('O') ? 'letterUsed' : ''" @click="getLetter('O')">O</div>
                        <div class="letter" :class="useLetter('P') ? 'letterUsed' : ''" @click="getLetter('P')">P</div>
                        <div class="letter" :class="useLetter('Q') ? 'letterUsed' : ''" @click="getLetter('Q')">Q</div>
                        <div class="letter" :class="useLetter('R') ? 'letterUsed' : ''" @click="getLetter('R')">R</div>
                        <div class="letter" :class="useLetter('S') ? 'letterUsed' : ''" @click="getLetter('S')">S</div>
                        <div class="letter" :class="useLetter('T') ? 'letterUsed' : ''" @click="getLetter('T')">T</div>
                        <div class="letter" :class="useLetter('U') ? 'letterUsed' : ''" @click="getLetter('U')">U</div>
                    </div>
                    <div class="row_letters row justify-content-center">
                        <div class="letter" :class="useLetter('V') ? 'letterUsed' : ''" @click="getLetter('V')">V</div>
                        <div class="letter" :class="useLetter('W') ? 'letterUsed' : ''" @click="getLetter('W')">W</div>
                        <div class="letter" :class="useLetter('X') ? 'letterUsed' : ''" @click="getLetter('X')">X</div>
                        <div class="letter" :class="useLetter('Y') ? 'letterUsed' : ''" @click="getLetter('Y')">Y</div>
                        <div class="letter" :class="useLetter('Z') ? 'letterUsed' : ''" @click="getLetter('Z')">Z</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.bg-word {
    margin: 0 !important;
    background-image: url('@/assets/img/sinfondo_estrellita.gif');
    /* Center and scale the image nicely */
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg_text {
    background-image: url('@/assets/img/pregunta1.png');
    background-position: center top;
    background-size: 100% 100%;
}

.bg_response {
    background-image: url('@/assets/img/respuesta.png');
    background-position: center top;
    background-size: 100% 100%;
}

.bg_query {
    background-image: url('@/assets/img/teclado.png');
    background-position: center top;
    background-size: 100% 100%;
}

.btn_center {
    height: 60px;
    top: 45%;
    position: absolute;
    left: 35%;
}

.letter {
    margin: 4px;
    /* width: 48px; */
    width: 7%;
    padding: 10px 0px 10px 0px;
    font-size: 26px;
    background: #76777a;
    color: #ffff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.letterUsed {
    opacity: 0.5;
    cursor: no-drop;
}

.div_trys {
    padding: 10px;
    border: 1px solid #000;
    border-radius: 10px;
    background: #e2221c;
    color: #fff;
}
</style>